import CableReady from "cable_ready";
import consumer from "channels/consumer";

const slAlert = document.querySelector("#slAlert");

export const followPresentationChannel = (id) =>
  consumer.subscriptions.create(
    { channel: "Education::FollowPresentationChannel", id },
    {
      connected () {
        // Called when the subscription is ready for use on the server
        console.log("[Education::FollowPresentationChannel] Connected", id);
      },

      disconnected () {
        // Called when the subscription has been terminated by the server
        slAlert.toast();

        // NOTE: This is where the disconnected errror comes from
        console.log("[Education::FollowPresentationChannel] DISconnected", id);
      },

      received (data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.cableReady) {
          CableReady.perform(data.operations);
        } else {
          console.log(`[Education::FollowPresentationChannel][${id}] Received data:`, data);
        }
      },
    },
  );

export const leadPresentationChannel = (id) =>
  consumer.subscriptions.create(
    { channel: "Education::LeadPresentationChannel", id },
    {
      connected () {
        // Called when the subscription is ready for use on the server
        console.log("[Education::LeadPresentationChannel] Connected", id);
      },

      disconnected () {
        // Called when the subscription has been terminated by the server
        console.log("[Education::LeadPresentationChannel] DISconnected", id);
      },

      received (data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.cableReady) {
          CableReady.perform(data.operations);
        } else {
          console.log(`[Education::LeadPresentationChannel][${id}] Received data:`, data);
        }
      },
    },
  );
