import { Controller } from "@hotwired/stimulus";
import { Sortable } from "sortablejs";

export default class extends Controller {
  static targets = [
    "container",
    "position",
    "item",
  ];

  connect () {
    this.sortable = Sortable.create(this.containerTarget, {
      animation: 250,
      chosenClass: "drag-and-drop__card--chosen",
      ghostClass: "drag-and-drop__card--ghost",
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.end.bind(this),
    });
  }

  end () {
    this.itemTargets.forEach((item, index) => {
      this._findTargetPosition(item).value = index;
    });
  }

  _findTargetPosition = (target) => {
    return this.positionTargets.find((position) => target.contains(position));
  };
}
