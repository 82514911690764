import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  connect () {
    /* global HTMLFormElement */
    this.form = this.element instanceof HTMLFormElement ? this.element : this.element.querySelector("form");
    if (this.form) {
      this.form.addEventListener("click", this.validateRequiredFields.bind(this));
      this.form.addEventListener("change", this.validateRequiredFields.bind(this));
      this.form.addEventListener("input", this.validateRequiredFields.bind(this));
      this.form.addEventListener("keyup", this.validateRequiredFields.bind(this));
      this.form.addEventListener("reset", this.validateRequiredFields.bind(this));

      this.validateRequiredFields();
    }
  }

  disconnect () {
    if (this.form) {
      this.form.removeEventListener("click", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("change", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("input", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("keyup", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("reset", this.validateRequiredFields.bind(this));
    }
  }

  validateRequiredFields () {
    const requiredFields = this.element.querySelectorAll("[required]");
    const isValid = Array.from(requiredFields).every((field) => field.value && field.value.length);

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !isValid;

      if (this.submitTarget.parentElement.nodeName === "SL-TOOLTIP") {
        this.submitTarget.parentElement.disabled = isValid;
      }
    }
  }
}
