import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from "../controllers/application_controller";
import CableReady from "cable_ready";

// Configure StimulusReflex
export const registerStimulusReflex = (application) => {
  application.consumer = consumer;
  CableReady.initialize({ consumer });

  StimulusReflex.initialize(application, { controller, isolate: true });

  // The next line tells eslint to accept the undef process var (this one is specifically injected into esbuild's build process)
  /* global process */
  StimulusReflex.debug = process.env.RAILS_ENV === "development";

  // Highlights ActionCable mutations in development (flash of green)
  if (process.env.RAILS_ENV === "development") {
    import("radiolabel").then(Radiolabel =>
      application.register("radiolabel", Radiolabel.default),
    );
  }
};
